<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner title="TDS" :breadcrumb="[{ label: 'TDS Report' }]"> </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div class="card custom-card" style="height: 100%">
              <div class="card-body">
                <!-- start list area  -->
                <div class="row">
                  <h4>
                    <b style="color: #00364f; font-weight: bolder">TDS Report</b>
                  </h4>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="margin-top: 7px"
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
              
                          <th>Projet ID</th>
                          <th>Service</th>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Status</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(category, index) in categories"
                            :key="index"
                          >
                            

                            <td class="text-truncate">
                              <span v-if="category.client_service">

                                {{category.client_service.clientServiceNo }}
                              </span>
                             </td>
                             <td class="text-truncate">
                              <span v-if="category.service
">

                                {{category.service.name }}
                              </span>
                             </td>

                            <td class="text-truncate">{{ dateTime(category.date) }}</td>
                            <td class="text-truncate">{{ category.amt }}</td>
                            <td class="text-truncate">
                              <p
                                v-if="category.status == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                         
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-7">
                    <ul class="pagination">
                      <li class="page-item" @click="loadTDSreport(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadTDSreport(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page > 3"
                        @click="loadTDSreport(pagination.current_page - 3)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page > 2"
                        @click="loadTDSreport(pagination.current_page - 2)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page > 1"
                        @click="loadTDSreport(pagination.current_page - 1)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li
                        class="active page-item"
                        @click="loadTDSreport(pagination.current_page)"
                      >
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadTDSreport(pagination.current_page + 1)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadTDSreport(pagination.current_page + 2)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadTDSreport(pagination.current_page + 3)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadTDSreport( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadTDSreport( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadTDSreport(pagination.last_page - 3)"
                      >
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadTDSreport(pagination.last_page - 2)"
                      >
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadTDSreport(pagination.last_page - 1)"
                      >
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadTDSreport(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadTDSreport(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-4 text-end">
                    Showing {{ pagination.from }} to {{ pagination.to }} of
                    {{ pagination.total }} entries
                  </div>
                  <div
                    class="col-1 text-end"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                  >
                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modaltitle" style="color: #00364f">
              <b>Category</b>
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <!-- @submit.prevent="(edit == 'false') ? saveDesignation() : designationUpdate()" -->
          <div class="modal-body">
            <form @submit.prevent="edit == 'false' ? saveCategory() : categoryUpdate()">
              <div class="row">
                <div class="col-12" v-if="msgImgError">
                  <div class="alert alert-danger p-1" role="alert">
                    <small>{{ msgImgError }}</small>
                  </div>
                </div>
                <div class="col-xl-4 col-md-4 col-12" style="padding: 5px 5px 5px 5px">
                  <div id="preview">
                    <img
                      style="width: 100px; height: 100px"
                      v-if="logoPreImg"
                      :src="logoPreImg"
                    />
                  </div>
                  <div class="form-group">
                    <div class="image-upload" style="padding: 5px 10px 5px 10px">
                      <b>Logo</b>&nbsp;&nbsp;
                      <label for="file-input2">
                        <img
                          style="width: 25px; height: 25px"
                          src="/assets/image/upload.png"
                        />
                      </label>
                      <input
                        class="input-file"
                        @change="logoUpload"
                        style="position: absolute; top: -150000px"
                        id="file-input2"
                        type="file"
                        accept="image/*"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                  <div class="form-group">
                    <lable><strong>Name</strong></lable>
                    <input
                      required
                      style="text-transform: uppercase; height: 30px"
                      class="form-control"
                      placeholder="Name"
                      v-model="form.name"
                      @change="lenthheker($event, 'catName')"
                    />
                    <span style="color: #f21000" id="catName"></span>
                  </div>
                </div>

                <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                  <div class="form-group">
                    <lable><strong>Status</strong></lable>
                    <!-- <input style="text-transform: uppercase;height:30px;" type="text" class="form-control" placeholder="description" > -->
                    <select
                      style="text-transform: uppercase; height: 30px; padding-top: 3px"
                      class="form-select"
                      aria-label="Default select example"
                      v-model="form.status"
                    >
                      <option value="true" selected>Active</option>
                      <option value="false">InActive</option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                  <div class="form-group">
                    <lable><strong>Description</strong></lable>
                    <textarea
                      style="text-transform: uppercase"
                      class="form-control"
                      rows="3"
                      placeholder="remark"
                      v-model="form.description"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn text-white btn-sm"
                  style="background-color: red"
                  data-bs-dismiss="modal"
                >
                  <b>Close</b>
                </button>
                <button
                  type="submit"
                  class="btn text-white btn-sm"
                  style="background-color: #00364f"
                >
                  <b>Confirm</b>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal addons  -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="categoryServices"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">
              <strong style="color: #00364f">Services</strong>
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="container-fluid table-scroll">
                  <table class="table table-hover table-sm">
                    <tr
                      class="text-white tr-head table-wrapper-scroll-y rounded-circle"
                      style="background-color: #00364f"
                    >
                      <th style="width: 10%">+</th>
                      <th style="width: 10%">Code</th>
                      <th style="width: 80%">Services</th>
                    </tr>
                    <tbody>
                      <tr v-for="(service, index) in services" :key="index">
                        <td>
                          <!-- <span v-if="service.id == otherPackageServices">
                              hi
                          </span>
                           <span v-else>
                             by
                          </span> -->
                          <input
                            @click="addNewServiceInCategory(service, $event)"
                            class="form-check-input"
                            type="checkbox"
                            :value="service.id"
                            v-model="catServices"
                          />
                        </td>
                        <td>
                          <label
                            for="flexCheckDefault"
                            style="color: #00364f; font-weight: 600"
                            v-if="service"
                          >
                            {{ service.code }}</label
                          >
                        </td>
                        <td>
                          <label
                            for="flexCheckDefault"
                            style="color: #00364f; font-weight: 600"
                            v-if="service"
                          >
                            {{ service.name }}</label
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm text-white"
              style="background-color: #f21000"
              data-bs-dismiss="modal"
            >
              <strong> Close</strong>
            </button>
            <!-- <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

//
<script>
import moment from "moment";
// import { toast } from "vue3-toastify";
// import "vue3-toastify/dist/index.css";
import Banner from "../../../components/staff/comman/Banner.vue";
import Spinner from "../../../components/staff/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "Categories",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 5,
      msgImgError: "",
      categoryservice: [],
      catServices: [],
      category_id: null,
      categoryService_Form: {
        service_category_id: null,
        service_id: null,
        remark: "",
      },
      services: [],
      extensionLogo: "",
      logoPreImg: this.$store.state.placeholderImg,
      form: {
        name: "",
        shortName: "",
        shortDescription: "",
        description: "",
        icon: "",
        logo: "",
        status: "true",
        remark: "",
      },
      edit: "false",
      cat_id: null,
      loading: false,
      categories: [],
    };
  },
  methods: {
    dateTime(value) {
    return moment(value).format("DD-MM-YYYY");

    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadTDSreport();
    },
    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "catName") {
        if (data.length < 3) {
          this.submitStatus = "true";
          $("#catName").html("<small>Category Name required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.submitStatus = "false";
          $("#catName").html("<small></small>");
        }
      }
    },

    loadTDSreport(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        pageUrl += `staff/tdscommission?user_id=${this.$route.params.id}&user_type=masterDistributer&per_page=${this.per_page}`;
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}&user_id=${this.$route.params.id}&user_type=masterDistributer`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}&user_id=${this.$route.params.id}&user_type=masterDistributer`;
      }
      if (page != null) {
        pageUrl += `&page=${page}&user_id=${this.$route.params.id}&user_type=masterDistributer`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.categories = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadTDSreport();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
</style>
